/* eslint-disable @nx/enforce-module-boundaries */
import { CircleIcon, NewGlobe, Globe , NewReply } from '@mybridge/icons';
import { CircleAndFollowers } from '@mybridge/icons/CircleAndFollowers';

export const PostVisibilityOptions = [
  {
    icon: <NewGlobe width={20} height={20} color="#868585" />,
    translationKey: 'createPost.everyone',
    title: 'Everyone',
    value: 0,
  },
  {
    icon: <CircleIcon width={20} height={20} color="#868585" />,
    translationKey: 'createPost.myCircle',
    title: 'myCircle',
    value: 1,
  },
  {
    icon: <CircleAndFollowers width={20} height={20} color="#868585" />,
    translationKey: 'createPost.myCircleFollowers',
    title: 'myCircle & followers',
    value: 2,
  },
];

export const PostCommentControlOptions = [
  {
    icon: <NewReply  width={20} height={25} color="#868585"/>,
    translationKey: 'createPost.everyone',
    title: 'Everyone',
    value: 0,
  },
  {
    icon: <CircleIcon width={20} height={20} color="#868585" />,
    translationKey: 'createPost.myCircle',
    title: 'myCircle',
    value: 1,
  },
  {
    icon: <CircleAndFollowers width={20} height={20} color="#868585" />,
    translationKey: 'createPost.myCircleFollowers',
    title: 'myCircle & followers',
    value: 2,
  },
];

export const EventSecurityOptions = [
  {
    icon: <Globe width={20} height={20} color="#868585"/>,
    translationKey: 'createPost.onlyCohost',
    title: 'Only Cohost',
    value: "COHOST",
  },
  {
    icon: <CircleIcon width={20} height={20} color="#868585" />,
    translationKey: 'createPost.everyone',
    title: 'Everyone',
    value: "EVERYONE",
  },
  {
    icon: <CircleAndFollowers width={20} height={20} color="#868585" />,
    translationKey: 'createPost.nobody',
    title: 'Nobody',
    value: "HOST",
  },
];
